import React, { useState } from "react";

const OTPInput = ({ onChange }) => {
  const [otp, setOtp] = useState(Array(6).fill("")); // State to hold OTP values

  // Handle change event for input fields
  const handleChange = (e, index) => {
    const { value } = e.target;
    if (/^\d*$/.test(value) && value.length <= 1) {
      // Allow only single digit
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      onChange && onChange(newOtp);
      // Move focus to next input field if value is entered
      if (index < 5 && value) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  // Handle key down event to move focus
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  return (
    <div className="d-flex">
      {otp.map((digit, index) => (
        <input
          key={index}
          id={`otp-input-${index}`}
          type="text"
          maxLength="1"
          className="form-control text-center mx-1"
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          style={{ width: "3rem", height: "3rem", fontSize: "1.5rem" }}
        />
      ))}
    </div>
  );
};

export default OTPInput;
