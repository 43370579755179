import React, { useEffect } from "react";
import axios from "axios";

function MediaPlayer({ fileId }) {
  const [mediaUrl, setMediaUrl] = React.useState("");
  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/view/${fileId}`,
          {
            responseType: "blob",
          }
        );
        const url = URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        setMediaUrl(url);
      } catch (error) {
        console.error("Error fetching media", error);
      }
    };

    fetchMedia();
  }, [fileId]);

  return (
    <div>
      {mediaUrl && (
        <audio controls>
          <source src={mediaUrl} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
    </div>
  );
}

export default MediaPlayer;
