import React from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { Carousel } from "react-bootstrap";

function Home() {
  return (
    <div>
      <Header />
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://via.placeholder.com/1920x500"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://via.placeholder.com/1920x500"
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://via.placeholder.com/1920x500"
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
      <div className="container mt-5">
        <div className="row">
          {Array.from({ length: 8 }).map((_, idx) => (
            <div className="col-md-3 mb-4" key={idx}>
              <div className="card">
                <div
                  className="card-body"
                  style={{
                    backgroundColor: `#${Math.floor(
                      Math.random() * 16777215
                    ).toString(16)}`,
                  }}
                >
                  <h5 className="card-title">Block {idx + 1}</h5>
                  <p className="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
