import React from "react";
import { useParams } from "react-router-dom";
import MediaPlayer from "../component/MediaPlayer";

function FileView() {
  const { fileId } = useParams();
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      {fileId && <MediaPlayer fileId={fileId} />}
    </div>
  );
}

export default FileView;
