import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import MessageBox from "../component/MessageBox";

const materialTypeId = 1;
const materialLoad = -1;
function Books() {
  const [message, setMessage] = useState(null);
  const [materialList, setMaterialList] = useState([]);
  const [loading, setLoading] = useState(null);
  const isLogin = secureLocalStorage.getItem("isLogin");
  const isAdmin = secureLocalStorage.getItem("isAdmin");
  const token = secureLocalStorage.getItem("token");

  useEffect(() => {
    if (!!isLogin && !!isAdmin) {
      setLoading(materialLoad);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/get_material_list/${materialTypeId}`,
          {
            headers: {
              JWTToken: token,
            },
          }
        )
        .then((response) => {
          setLoading(null);
          setMaterialList(response.data.data);
        })
        .catch((error) => {
          setLoading(null);
          setMessage({
            type: "error",
            message: error.message,
          });
        });
    }
  }, [isAdmin, isLogin]);
  const handleButtonClick = (id) => {
    setLoading(id);
    axios
      .get(`${process.env.REACT_APP_API_URL}/sync_material_data/${id}`, {
        headers: {
          JWTToken: token,
        },
      })
      .then((response) => {
        setLoading(null);
        if (response.data.success) {
          setMessage({
            type: "success",
            message: `Data Sync successfully for ${response.data.materialName} Total Languages:${response.data.totalLanguage} & Total Pages:${response.data.totalContent}`,
          });
        } else {
          setMessage({
            type: "error",
            message: response.data.message,
          });
        }
      })
      .catch((error) => {
        setMessage({
          type: "error",
          message: error.message,
        });
        setLoading(null);
      });
  };
  if (!isAdmin || !isLogin)
    return <MessageBox type="error" message="You donot have permission" />;

  return (
    <>
      <Header />
      <div className="container m-3">
        <h3>Book List</h3>

        {loading === materialLoad && <div className="loader" />}

        {message && message.type && (
          <MessageBox type={message.type} message={message.message} />
        )}
        <div className="row">
          {materialList.map((item) => (
            <div key={item.ID} className="col-md-3 mb-3 d-flex">
              {loading === item.ID ? (
                <div className="loader" />
              ) : (
                <button
                  className="btn btn-primary btn-block w-100 h-100"
                  onClick={() => handleButtonClick(item.ID)}
                >
                  {item.NAME}
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Books;
