import React from "react";

function Footer() {
  return (
    <footer className="bg-light text-center text-lg-start">
      
      <div
              style={{
                backgroundColor: "#354C30",
                padding: "30px",
                color: "#FFFFFF",
                textAlign: "center",
              }}
            >
              <div className="d-flex justify-content-center mb-3">
                <a
                  href="https://www.facebook.com/GowellSolutions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mx-3"
                >
                  <img
                    src="https://s3.af-south-1.amazonaws.com/onlineguides/Pearl%20Project/Facebook.png"
                    alt="Facebook"
                    height="30"
                    width="30"
                  />
                </a>
                <a
                  href="https://www.gowell.solutions/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mx-3"
                >
                  <img
                    src="https://s3.af-south-1.amazonaws.com/onlineguides/Pearl%20Project/Web.png"
                    alt="Web"
                    height="30"
                    width="30"
                  />
                </a>
                <a
                  href="https://www.instagram.com/gowellsolutions/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mx-3"
                >
                  <img
                    src="https://s3.af-south-1.amazonaws.com/onlineguides/Pearl%20Project/Instagram.png"
                    alt="Instagram"
                    height="30"
                    width="30"
                  />
                </a>
              </div>
              <p>
                For general inquiry, any contribution or support, email us at{" "}
                <a
                  href="mailto:info@gowell.solutions"
                  style={{ color: "#FFFFFF", textDecoration: "underline" }}
                >
                  info@gowell.solutions
                </a>
              </p>
            </div>
    </footer>
  );
}

export default Footer;
